import { trans } from '@/utils/translation'
import { router, usePage } from '@inertiajs/react'
import { Card, CardActions, CardContent, CardHeader, Stack } from '@mui/material'
import { useForm } from '@/hooks/use-form'
import { ToastCtl } from '@/utils/toast-ctl'
import { useModalContext } from '@/context/use-modal-context'
import { FormProvider } from '@/context/use-form-context'
import { LoadingButton } from '@mui/lab'
import InertiaCheckbox from '@/components/form/inertia-checkbox'
import BillingState from '@/constants/BillingState'
import Typography from '@mui/material/Typography'
import { formatDate } from '@/utils/format'
import dayjs from 'dayjs'
import BillingResetModal from '@/sections/billing/view/billing-reset-modal'
import { useEffect } from 'react'

export default function BillingSubmitPanel({ setErrors }) {
  const { billing } = usePage().props
  const { openModal, closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/billings/${billing.id}/edit`
    },
    defaultValues: {
      state: billing.state,
      terms: false,
      begin: billing.begin,
      end: billing.end
    }
  })

  const { submit, submitting } = methods

  useEffect(() => {
    setErrors(methods.errors)
  }, [methods.errors])

  const handleSubmit = (state) => {
    submit(
      () => {
        router.reload()
        ToastCtl.show(trans('general.save_success'), 'success')
        closeModal('billingEdit')
      },
      null,
      {
        data: {
          state
        }
      }
    )
  }

  return (
    <>
      <Card>
        <CardHeader title={trans('billing.detail.submit.header')} />
        <CardContent sx={{ pb: 1 }}>
          <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
            <form onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <InertiaCheckbox
                  name={'terms'}
                  input={{
                    label: <div dangerouslySetInnerHTML={{ __html: trans('billing.detail.submit.terms') }}></div>,
                    disabled: billing.state === BillingState.DONE
                  }}
                />
              </Stack>
            </form>
          </FormProvider>
        </CardContent>
        <CardActions>
          <Stack pl={2} pb={2}>
            <Stack direction={'row'} spacing={2} pb={1}>
              <LoadingButton
                disabled={billing.state !== BillingState.BILLABLE && billing.state !== BillingState.REVIEW}
                loading={submitting}
                variant={'contained'}
                onClick={() => {
                  handleSubmit(BillingState.SUBMITTED)
                }}
              >
                {trans('billing.detail.submit.submit_button')}
              </LoadingButton>
              {billing.state === BillingState.SUBMITTED &&
                dayjs(billing.submitDate).add(28, 'days').isAfter(dayjs()) && (
                  <LoadingButton
                    loading={submitting}
                    variant={'outlined'}
                    onClick={() => {
                      openModal(
                        'billingReset',
                        <BillingResetModal
                          callback={() => {
                            handleSubmit(BillingState.BILLABLE)
                          }}
                        />
                      )
                    }}
                  >
                    {trans('billing.detail.submit.reset_button')}
                  </LoadingButton>
                )}
            </Stack>
            {billing.submitDate && (
              <Typography variant={'caption'}>
                {trans('billing.detail.submit.submit_success')} ({formatDate(billing.submitDate)})
              </Typography>
            )}
          </Stack>
        </CardActions>
      </Card>
    </>
  )
}
