import {Card, Checkbox, Stack, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useEffect, useMemo, useState} from "react";
import useRemoteTable from "../../hooks/useRemoteTable";
import TableRowAction from "../../components/table/table-row-action";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import Iconify from "../../components/iconify";
import {defaultTableOptions} from "../../utils/misc";
import {useBoolean} from "../../hooks/use-boolean";
import TipTableToolbar from "./table/tip-table-toolbar";
import { COLUMNS } from "./table/tip-table-definitions";
import TableConfirmDelete from "../../components/table/table-confirm-delete";
import PropTypes from 'prop-types';
import {trans} from "../../utils/translation";
import { hasRole } from '@/utils/misc'
import Roles from '@/constants/Roles'
import {router, usePage} from "@inertiajs/react";
import TipEditModal from "./tip-edit-modal";
import {useModalContext} from "../../context/use-modal-context";
import {RemoveOutlined} from "@mui/icons-material";
import Button from "@mui/material/Button";
import TipState from "../../constants/TipState";
import TipRetrieveModal from "./tip-retrieve-modal";


export default function TipListTable({ defaultFilter, defaultSorting }) {

  const { auth } = usePage().props
  const path = '/tips/list'
  const theme = useTheme()
  const confirmRows = useBoolean()
  const [tipEntry, setTipEntry] = useState(null)
  const [item, setItem] = useState(null)
  const { openModal } = useModalContext()


  const columns = useMemo(() => COLUMNS, [])
  const methods = useRemoteTable(path, ['name', 'user'], defaultFilter, defaultSorting)
  const {
    globalFilter,
    columnSorting,
    setColumnSorting,
    pagination,
    setPagination,
    data,
    isError,
    isLoading,
    rowCount,
    rowSelection,
    setRowSelection,
    setRefresh
  } = methods

  // Clear selection on paginate
  useEffect(() => {
    setRowSelection([])
  }, [pagination])

  // Update sidebar item on data change
  useEffect(() => {
    if (item?.id) {
      setItem(data.filter((data) => data.id === item.id)[0])
    }
  }, [data])


  const table = useMaterialReactTable({
    ...defaultTableOptions,
    enableRowSelection: (row) => row.original.state === TipState.PAYABLE,
    enableMultiRowSelection: true,
    enableRowActions: true,
    renderRowActions: ({ row }) => {
      return(
      hasRole(auth.user, Roles.ROLE_ADMIN) && (
        <TableRowAction
          row={row}
          width= 'fit-content'
          items={[
            {
              content: (
                <>
                  <Iconify icon={'eva:edit-outline'} />
                  <Typography variant={'body'}>{trans('button.edit')}</Typography>
                  </>
              ),
              callback: () => {
                openModal('tipEdit', <TipEditModal tipEntry={row.original} callback={() => setRefresh(new Date())}/>)

              }
            },
            {
              content: (
                <>
                  <Iconify icon={'eva:eye-outline'}/>
                  <Typography variant={'body'}>{trans('tip.view_document')}</Typography>
                </>
              ),
              callback: () => {
                row.original?.tipDocument &&
                window.open(`/documents/${row.original?.tipDocument?.documentId}/view`, '_blank')
              },
              disabled: !row.original?.tipDocument
            },
            {
              content: (
                <>
                  <Iconify icon={'eva:trash-2-outline'} color={theme.palette.error.main} />
                  <Typography variant={'body'}>{trans('button.delete')}</Typography>
                </>
              ),
              callback: () => {
                setTipEntry(row.original)
                confirmRows.onTrue()
              }
            }
          ]}
        />)
      )},
    renderTopToolbar: () => <TipTableToolbar {...methods} />,
    positionToolbarAlertBanner: 'head-overlay',
    muiToolbarAlertBannerProps: isError ? { severity: 'error' } : { severity: 'warning' },
    renderToolbarAlertBannerContent: () => {
      if (isError) {
        return trans('table.data_load_error')
      }

      return (
        <Stack direction={'row'} sx={{ px: 1 }} alignItems={'center'} justifyContent={'space-between'}>
          <Stack direction={'row'} alignItems={'center'}>
            <Checkbox
              sx={{ mr: 3 }}
              checkedIcon={
                <RemoveOutlined
                  sx={{
                    background: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    borderRadius: '5px'
                  }}
                />
              }
              defaultChecked
              onClick={() => setRowSelection([])}
            />
            <Typography fontSize={'small'}>
              {Object.values(rowSelection).length} {trans('table.selected')}
            </Typography>
          </Stack>
            <Button
              variant="contained"
              color={'primary'}
              sx={{m: 1, mr: 4}}
              onClick={() => {
                const selectedTips = Object.keys(rowSelection)
                  .map(rowIndex => data[rowIndex])
                openModal('tipRetrieve', <TipRetrieveModal setRefresh={setRefresh} tips={selectedTips} setRowSelection={setRowSelection} />);
              }}
            >
              {trans('tip.retrieve_tips')}
            </Button>
        </Stack>
      )
    },
    columns,
    data,
    rowCount: rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setColumnSorting,
    onRowSelectionChange: setRowSelection,
    muiTableBodyCellProps: ({ cell, column, row, table }) => ({
      sx : {
       color: row.original.state === TipState.PENDING ? theme.palette.text.disabled : theme.palette.text.primary
      }
    }),
    state: {
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isLoading,
      showSkeletons: isLoading,
      sorting: columnSorting,
      rowSelection,
    }
  })
  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>

      <TableConfirmDelete
        url={`/api/tip/${tipEntry?.id}`}
        confirmRows={confirmRows}
        setValue={setTipEntry}
        setRefresh={setRefresh}
        />
    </>
  )
}

TipListTable.propTypes = {
  defaultFilter: PropTypes.object,
  defaultSorting: PropTypes.array
}