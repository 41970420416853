import { DialogActions, DialogTitle, Stack, Typography } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import DocumentTypes from '@/constants/DocumentTypes'
import InertiaFileDragDrop from '@/components/form/inertia-file-drag-drop'
import PropTypes from 'prop-types'

export default function TipRetrieveModal({ setRefresh, tips, setRowSelection }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'POST',
      location: `/documents/upload`,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    },
    defaultValues: {
      type: DocumentTypes.USER_UPLOADED,
      date: new Date(),
      file: null,
      tips: tips ? tips.map((tip) => tip.id) : []
    }
  })

  console.log(tips)

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(
      () => {
        router.reload()
        ToastCtl.show(trans('general.save_success'), 'success')
        closeModal('tipRetrieve')
        setRefresh(new Date())
        setRowSelection([])
      },
      (e) => {
        if (e?.response?.data?.status === 422) return

        ToastCtl.show(trans('general.upload_error'), 'error')
      }
    )
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans('tip.upload')}</DialogTitle>
      <DialogContent dividers>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack direction={'column'} spacing={2}>
              <Typography variant={'body2'}>
                {trans('tip.retrieve', { amount: tips?.reduce((acc, current) => current.amount + acc, 0) })}
              </Typography>
              <InertiaFileDragDrop
                name={'file'}
                input={{
                  multiple: false
                }}
              />
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('tipRetrieve')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

TipRetrieveModal.propTypes = {
  setRefresh: PropTypes.func.isRequired,
  tips: PropTypes.array,
  setRowSelection: PropTypes.func
}
