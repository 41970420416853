import { useMemo } from 'react'
import { trans } from '@/utils/translation'
import { usePage } from '@inertiajs/react'
import { hasRole } from '@/utils/misc'
import Roles from '@/constants/Roles'
import Iconify from '@/components/iconify'

export function useNavData() {
  const { auth } = usePage().props

  const icon = (name) => <Iconify icon={name} sx={{ width: '24px', height: '24px' }} />
  const isAdmin = hasRole(auth.user, Roles.ROLE_ADMIN)
  const isOwner = hasRole(auth.user, Roles.ROLE_PROPERTY_OWNER)
  const isManager = hasRole(auth.user, Roles.ROLE_PROPERTY_ADMIN)

  const hasBilling = auth.user.billingAllowed >= 1 && (isOwner || isManager)
  const hasProperties = auth.user.propertiesCount >= 1 && (isOwner || isManager)

  const items = [
    {
      title: trans('menu.dashboard'),
      path: '/',
      icon: icon('carbon:chart-histogram'),
      active: document.location.pathname === '/' || document.location.pathname.startsWith('/dashboard')
    }
  ]

  if (isAdmin || hasProperties) {
    items.push({
      title: trans('menu.properties'),
      path: '/properties',
      icon: icon('carbon:building'),
      active: document.location.pathname.startsWith('/properties')
    })
  }

  if (auth.user.apartmentsCount > 0) {
    items.push({
      title: trans('menu.apartments'),
      path: '/apartments',
      icon: icon('carbon:building'),
      active: document.location.pathname.startsWith('/apartments')
    })
  }

  if (isAdmin || isOwner || isManager) {
    items.push({
      title: trans('menu.devices'),
      path: '/devices',
      icon: icon('carbon:meter'),
      active: document.location.pathname.startsWith('/devices')
    })
  }

  if (isAdmin) {
    items.push({
      title: trans('menu.managers'),
      path: '/managers',
      icon: icon('carbon:user'),
      active: document.location.pathname.startsWith('/managers')
    })
  }

  if (isAdmin || isManager) {
    items.push({
      title: trans('menu.owners'),
      path: '/owners',
      icon: icon('carbon:user'),
      active: document.location.pathname.startsWith('/owners')
    })
  }

  if (isAdmin || isManager || isOwner) {
    items.push({
      title: trans('menu.tenants'),
      path: '/tenants',
      icon: icon('carbon:user'),
      active: document.location.pathname.startsWith('/tenants')
    })
  }

  items.push({
    title: trans('menu.documents'),
    path: '/documents',
    icon: icon('carbon:document'),
    active: document.location.pathname.startsWith('/documents')
  })

  if (hasBilling || isAdmin) {
    items.push({
      title: trans('menu.billing'),
      path: '/billings',
      icon: icon('carbon:send-alt'),
      active: document.location.pathname.startsWith('/billings')
    })
  }

  if (isAdmin) {
    items.push(
      {
        title: trans('menu.assignments'),
        path: '/assignments',
        icon: icon('carbon:intent-request-upgrade'),
        active: document.location.pathname.startsWith('/assignments')
      },
      {
        title: trans('menu.appointments'),
        path: '/appointments',
        icon: icon('carbon:calendar'),
        active: document.location.pathname.startsWith('/appointments')
      },
      {
        title: trans('menu.log'),
        path: '/logs',
        icon: icon('carbon:catalog'),
        active: document.location.pathname.startsWith('/logs')
      },
      {
        title: trans('menu.news'),
        path: '/news',
        icon: icon('carbon:book'),
        active: document.location.pathname.startsWith('/news')
      }
    )
  }

  if (isAdmin || auth.user.settings?.canManageTips) {
    items.push({
      title: trans('menu.tips'),
      path: '/tips',
      icon: icon('carbon:document'),
      active: document.location.pathname.startsWith('/tips')
    })
  }

  return useMemo(
    () => [
      {
        subheader: 'Menü',
        items
      }
    ],
    [auth.user.id, document.location.pathname]
  )
}
