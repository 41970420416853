import { DialogActions, DialogTitle, Grid } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { FormProvider } from '@/context/use-form-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import InertiaTextfield from '@/components/form/inertia-textfield'
import PropTypes from 'prop-types'

export default function AddressModal({ address }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: address?.id ? 'PUT' : 'POST',
      location: address?.id ? `/api/addresses/${address.id}/edit` : `/api/addresses`
    },
    defaultValues: {
      name: address?.name,
      company: address?.company,
      street: address?.street,
      streetNumber: address?.streetNumber,
      postcode: address?.postcode,
      city: address?.city,
      email: address?.email,
      isBillingAddress: address?.isBillingAddress ?? false
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('addressEdit')
    })
  }

  useSubmit(() => handleSubmit())
  return (
    <>
      <DialogTitle>{trans('address.edit')}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Grid container direction={'row'} spacing={2}>
              {address?.isBillingAddress && (
                <>
                  <Grid item md={6} xs={12}>
                    <InertiaTextfield
                      name={'name'}
                      input={{
                        label: trans('field.name'),
                        required: address?.isBillingAddress ?? false
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InertiaTextfield
                      name={'company'}
                      input={{
                        label: trans('field.company')
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item md={6} xs={12}>
                <InertiaTextfield
                  name={'street'}
                  input={{
                    label: trans('field.street'),
                    required: true
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaTextfield
                  name={'streetNumber'}
                  input={{
                    label: trans('field.street_number'),
                    required: true
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaTextfield
                  name={'postcode'}
                  input={{
                    label: trans('field.zip'),
                    required: true
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaTextfield
                  name={'city'}
                  input={{
                    label: trans('field.city'),
                    required: true
                  }}
                />
              </Grid>
              {address?.isBillingAddress && (
                <Grid item md={6} xs={12}>
                  <InertiaTextfield
                    name={'email'}
                    input={{
                      label: trans('field.email'),
                      required: false
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('addressEdit')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

AddressModal.propTypes = {
  address: PropTypes.object
}
