import CustomPopover, { usePopover } from '@/components/custom-popover'
import IconButton from '@mui/material/IconButton'
import Iconify from '@/components/iconify'
import MenuItem from '@mui/material/MenuItem'
import PropTypes from 'prop-types'

export default function TableRowAction({ items, width = 140 }) {
  const popover = usePopover()

  return (
    <>
      <IconButton
        color={popover.open ? 'inherit' : 'default'}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          popover.onOpen(e)
        }}
      >
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
      <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top" sx={{ width }}>
        {items.map((item, index) => (
          <MenuItem
            key={index}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              item.callback()
              popover.onClose()
            }}
            disabled={item.disabled || false}
          >
            {item.content}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  )
}

TableRowAction.propTypes = {
  items: PropTypes.array,
  width: PropTypes.number
}
