import { DialogActions, DialogTitle, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import PropTypes from 'prop-types'
import UserState from '@/constants/UserState'
import InertiaSwitch from '@/components/form/inertia-switch'
import InertiaAutocomplete from '@/components/form/inertia-autocomplete'
import Roles from '@/constants/Roles'

export default function UserEditAdminModal({ user }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/users/${user.id}/edit-admin`
    },
    defaultValues: {
      state: user.state,
      role: user.roles[0],
      canManageOptimize: user.settings.canManageOptimize,
      canManageTips: user.settings.canManageTips,
      notifyViaEmail: user.settings.notifyViaEmail
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('userDataEditAdmin')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans(`user.edit`)}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack direction={'column'} spacing={2}>
              <InertiaAutocomplete
                name={'state'}
                input={{
                  label: trans('field.state')
                }}
                items={Object.values(UserState).map((state) => ({ id: state, label: trans(`state.user.${state}`) }))}
              />
              <InertiaAutocomplete
                name={'role'}
                input={{
                  label: trans('field.role')
                }}
                items={Object.values(Roles)
                  .filter((role) => role !== Roles.ROLE_ADMIN)
                  .map((role) => ({ id: role, label: trans(`role.${role}`) }))}
              />
              <InertiaSwitch
                name={'canManageOptimize'}
                input={{
                  label: trans('user.functions.use_optimize')
                }}
              />
              <InertiaSwitch
                name={'canManageTips'}
                input={{
                  label: trans('user.functions.manage_tips')
                }}
              />
              <InertiaSwitch
                name={'notifyViaEmail'}
                input={{
                  label: trans('user.functions.email_notification')
                }}
              />
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('userDataEditAdmin')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

UserEditAdminModal.propTypes = {
  user: PropTypes.object
}
