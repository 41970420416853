import { memo } from "react"
import Stack from "@mui/material/Stack"
import PropTypes from "prop-types"
import Label from "../../components/label"
import { STATUS_OPTIONS } from './table/tip-table-definitions'

function TipStateLabel({state}) {
    const colorVariant = STATUS_OPTIONS[state]?.color || 'default'
    const translation = STATUS_OPTIONS[state]?.label

    return (
        <Stack sx={{background: '#fff', borderRadius: '6px', display: 'inherit'}}>
            <Label variant="soft" color={colorVariant}>
                {translation}
            </Label>
        </Stack>
    );
}

TipStateLabel.propTypes = {
    state: PropTypes.string
}

export default memo(TipStateLabel)