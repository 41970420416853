import {Head} from "@inertiajs/react";
import {trans} from "../../utils/translation";
import DashboardLayout from "../../layouts/dashboard";
import TipListView from "../../sections/tip/view/tip-list-view";

const TipListPage = () => {
  return(
  <>
    <Head>
      <title>{trans('tip.title')}</title>
    </Head>
    <TipListView />
  </>
  )
}

TipListPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>

export default TipListPage