import { trans } from '@/utils/translation'
import { Card, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import UserHeader from '@/sections/user/user-header'
import background from '../../../../images/Cover/watercolour-colourful.jpg'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { DocumentListView } from '@/sections/document/view'
import { usePage } from '@inertiajs/react'
import ManagerDetailPage from '@/sections/manager/manager-detail-page'
import { TenantListView } from '@/sections/tenant/view'
import InfoView from '@/components/info-view'
import { OwnerListView } from '@/sections/owner/view'
import { useRememberTab } from '@/hooks/use-remember-tab'

const TABS = [
  {
    value: 'data',
    label: trans('profile.tabs.data')
  },
  {
    value: 'owners',
    label: trans('profile.tabs.owner')
  },
  {
    value: 'tenants',
    label: trans('profile.tabs.tenant')
  },
  {
    value: 'documents',
    label: trans('profile.tabs.documents')
  }
]

export default function ManagerDetailView() {
  const { user } = usePage().props
  const [currentTab, handleChangeTab] = useRememberTab('manager', user.id)

  return (
    <>
      <Typography variant={'h5'} gutterBottom>
        {trans('manager.header')}
      </Typography>

      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} flexWrap={'wrap'}>
        <Card
          sx={{
            mb: 3,
            height: 290,
            minWidth: { md: 600 },
            flexGrow: 10
          }}
        >
          <UserHeader role={trans('general.manager')} coverUrl={background} />

          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            sx={{
              width: 1,
              bottom: 0,
              zIndex: 9,
              position: 'absolute',
              bgcolor: 'background.paper',
              [`& .${tabsClasses.flexContainer}`]: {
                pr: { md: 3 },
                justifyContent: {
                  xs: 'unset',
                  md: 'flex-end'
                },
                mx: { xs: 'auto', md: 1 },
                width: { xs: 'fit-content', md: 'unset' }
              }
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} value={tab.value} label={tab.label} />
            ))}
          </Tabs>
        </Card>

        <Card
          sx={{
            mb: 3,
            maxHeight: 290,
            flexGrow: 1
          }}
        >
          <InfoView
            header={trans('manager.info.header')}
            description={trans('dashboard.info.description')}
            tip={trans('dashboard.info.tip')}
            button={trans('dashboard.info.button')}
          />
        </Card>
      </Stack>

      {currentTab === 'data' && <ManagerDetailPage />}
      {currentTab === 'tenants' && <TenantListView defaultFilter={{ createdBy: user.id, type: 'CURRENT_TENANT' }} />}
      {currentTab === 'owners' && <OwnerListView defaultFilter={{ createdBy: user.id, type: 'CURRENT_OWNER' }} />}
      {currentTab === 'documents' && <DocumentListView defaultFilter={{ user: user.id }} />}
    </>
  )
}
