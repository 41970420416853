import { useModalContext } from '../../context/use-modal-context'
import { router, usePage } from '@inertiajs/react'
import { useForm } from '@/hooks/use-form'
import { trans } from '../../utils/translation'
import { ToastCtl } from '../../utils/toast-ctl'
import { DialogActions, DialogTitle, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import DialogContent from '@mui/material/DialogContent'
import { FormProvider } from '../../context/use-form-context'
import { LoadingButton } from '@mui/lab'
import CustomButton from '../../components/custom-button'
import useSubmit from '../../hooks/use-submit'
import InertiaTextfield from '../../components/form/inertia-textfield'
import Iconify from '../../components/iconify'
import { useTheme } from '@mui/material/styles'
import { handleErrorResponse } from '@/utils/misc'
import useSingletonAxios from '@/hooks/use-singleton-axios'
import Box from '@mui/material/Box'

export default function TipLinkedEmailModal() {
  const { closeModal } = useModalContext()
  const { auth } = usePage().props
  const theme = useTheme()
  const request = useSingletonAxios()

  const methods = useForm({
    url: {
      method: 'POST',
      location: `/api/tipster-email/${auth.user.id}/add`
    },
    defaultValues: {
      email: null
    }
  })

  const { submit, submitting, setOverrideFieldValues } = methods

  const handleSubmit = () => {
    submit(() => {
      setOverrideFieldValues({ ['email']: '' })
      router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans('tip.linked_email')}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <Stack direction={'column'} spacing={2}>
          {auth.user.tipsterEmails.map((tipsterEmail, index) => (
            <Stack key={index} direction={'row'} spacing={2}>
              <Typography key={index} color={tipsterEmail.confirmed ? 'textPrimary' : 'textSecondary'}>
                {tipsterEmail.email}
              </Typography>
              <Iconify
                icon={'eva:trash-2-outline'}
                color={theme.palette.error.main}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  request({
                    method: 'DELETE',
                    url: `/api/tipster-email/${tipsterEmail.id}/delete`
                  })
                    .then(() => {
                      router.reload()
                      ToastCtl.show(trans('general.delete_success'), 'success')
                    })
                    .catch((e) => handleErrorResponse(e))
                }}
              />
            </Stack>
          ))}
        </Stack>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack direction={'column'} spacing={2} sx={{ mb: 2 }}>
              <Stack direction={'column'} spacing={2}>
                <Stack direction={'row'} spacing={2}></Stack>
              </Stack>
              <Stack direction={'row'} spacing={2}>
                <InertiaTextfield
                  name={'email'}
                  input={{
                    label: trans('tip.email')
                  }}
                />
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <LoadingButton
                    loading={submitting}
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    {trans('button.add')}
                  </LoadingButton>
                </Box>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            closeModal('linkedEmails')
          }}
        >
          {trans('button.close')}
        </CustomButton>
      </DialogActions>
    </>
  )
}
