import CustomPopover, { usePopover } from "../../../components/custom-popover"
import { FormControl, Stack } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import Iconify from "../../../components/iconify"
import MenuItem from "@mui/material/MenuItem"
import { trans } from "../../../utils/translation"
import ListFilterTextfield from "../../../components/form/list-filter-textfield"


export default function MailTableToolbar({
  globalFilter,
  setGlobalFilter,
  resetAll
}) {
  const popover = usePopover()

  return (
    <Stack>
      <Stack
        direction={'row'}
        alignItems={'center'}
        flexWrap={'wrap'}
        spacing={2}
        flexGrow={1}
        sx={{
          p: 2
        }}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          sx={{
            flexGrow: 1,
            width: { xs: 1, md: 200 }
          }}
        >
          <FormControl sx={{ flexGrow: 1 }}>
            <ListFilterTextfield
              placeholder={'Nach E-Mail Adresse suchen'}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </FormControl>
          <IconButton onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </Stack>
      </Stack>

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top" sx={{ width: 270 }}>
        <MenuItem
          onClick={() => {
            resetAll()
            popover.onClose()
          }}
        >
          <Iconify icon={'carbon:reset'} sx={{ width: 24, height: 24, mr: '16px' }} />
          {trans('table.reset_filter')}
        </MenuItem>
      </CustomPopover>
    </Stack>
  )
}
