import { useMemo } from 'react'
import { trans } from '@/utils/translation'
import { Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import CustomButton from '@/components/custom-button'
import { router, usePage } from '@inertiajs/react'
import CollapseCard from '@/components/collapse-card'
import Typography from '@mui/material/Typography'
import DeviceCard from '@/sections/billing/components/device-card'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { formatDate, formatDeviceValue } from '@/utils/format'
import DeviceValueTypes from '@/constants/DeviceValueTypes'
import IconButton from '@mui/material/IconButton'
import Iconify from '@/components/iconify'
import axios from 'axios'
import { ToastCtl } from '@/utils/toast-ctl'
import DeviceValueEditModal from '@/sections/device-value/device-value-edit-modal'
import DeviceValueCreateModal from '@/sections/device-value/device-value-create-modal'
import { useModalContext } from '@/context/use-modal-context'
import DeviceCreateEditModal from '@/sections/device/device-create-edit-modal'
import OverlayCard from '@/sections/billing/components/overlay-card'
import PropTypes from 'prop-types'

export default function BillingManualDevicesTable({ expanded, onExpand, editForbidden }) {
  const { billing } = usePage().props
  const { openModal } = useModalContext()

  const columns = useMemo(
    () => [
      {
        id: 'number',
        header: trans('billing.detail.device_value.columns.number'),
        Cell: ({ row }) => row.index + 1,
        size: 50,
        enableSorting: false
      },
      {
        accessorKey: 'label',
        header: trans('billing.detail.device_value.columns.apartment'),
        size: 150,
        enableSorting: false
      },
      {
        id: 'tenants',
        header: trans('billing.detail.device_value.columns.change_tenant'),
        Cell: ({ row }) => (row.original.tenants.length > 0 ? row.original.tenants.length - 1 : 0),
        size: 100,
        enableSorting: false
      },
      {
        id: 'devices',
        header: trans('billing.detail.device_value.columns.device'),
        Cell: ({ row }) => row.original.devices?.length ?? 0,
        size: 100,
        enableSorting: false
      }
    ],
    []
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => <></>,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (e) => {
        if (e.target.nodeName === 'TD' && !e.target.closest('.Mui-TableBodyCell-DetailPanel')) {
          row.toggleExpanded(!row.getIsExpanded())
        }
      },
      sx: { cursor: 'pointer' }
    }),
    muiDetailPanelProps: () => ({
      sx: {
        background: (theme) => theme.palette.grey[200]
      }
    }),
    renderDetailPanel: ({ row }) => {
      return (
        <>
          {row.original.devices.length > 0 &&
            row.original.devices.map((device) => (
              <DeviceCard
                sx={{ mb: 2 }}
                key={device.id}
                header={
                  <Stack direction="row" spacing={2} alignItems={'center'}>
                    <Typography variant="body2">{device.number}</Typography>
                    <Typography variant="body2">{trans(`device.type.${device.type}`)}</Typography>
                  </Stack>
                }
              >
                <Box p={2}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableBody>
                        {device.deviceValues.map((deviceValue) => (
                          <TableRow key={device.id}>
                            <TableCell>{formatDate(deviceValue.reportDate)}</TableCell>
                            <TableCell>{formatDeviceValue(deviceValue.value, device.type, deviceValue.unit)}</TableCell>
                            {!editForbidden && (
                              <TableCell>
                                {deviceValue.type === DeviceValueTypes.MANUAL && (
                                  <Stack direction={'row'} spacing={1} sx={{ mr: 1 }}>
                                    <IconButton
                                      key={'editHeatingCost'}
                                      onClick={() =>
                                        openModal(
                                          'deviceValueEdit',
                                          <DeviceValueEditModal deviceValue={deviceValue} device={device} />
                                        )
                                      }
                                    >
                                      <Iconify icon="solar:pen-bold" />
                                    </IconButton>
                                    <IconButton
                                      key={'delete'}
                                      sx={{ color: 'error.main' }}
                                      onClick={() => {
                                        axios
                                          .delete(`/api/device_values/${deviceValue.id}`)
                                          .then(() => router.reload())
                                          .catch((e) => ToastCtl.show(e.response?.data?.detail ?? e.message, 'error'))
                                      }}
                                    >
                                      <Iconify icon="solar:trash-bin-trash-bold" />
                                    </IconButton>
                                  </Stack>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                        <TableRow key={device.id}>
                          <TableCell>
                            {device.deviceValues.length === 0 && trans('billing.detail.device_value.no_device_value')}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <CustomButton
                    variant="contained"
                    color="primary"
                    disabled={editForbidden}
                    onClick={() => openModal('deviceValueCreate', <DeviceValueCreateModal device={device} />)}
                  >
                    + {trans('billing.detail.device_value.add_device_value')}
                  </CustomButton>
                </Box>
              </DeviceCard>
            ))}
          <CustomButton
            variant="contained"
            color="primary"
            disabled={editForbidden}
            onClick={() =>
              openModal(
                'deviceCreate',
                <DeviceCreateEditModal device={{ property: billing.property, apartment: row.original }} />
              )
            }
          >
            + {trans('billing.detail.device_value.add_device')}
          </CustomButton>
        </>
      )
    },
    columns,
    data: billing.apartments
  })

  return (
    <>
      <OverlayCard>
        <CollapseCard title={trans('billing.detail.device_value.title')} expand={expanded} onExpand={onExpand}>
          <MaterialReactTable table={table} />
        </CollapseCard>
      </OverlayCard>
    </>
  )
}

BillingManualDevicesTable.propTypes = {
  expanded: PropTypes.bool,
  onExpand: PropTypes.func,
  editForbidden: PropTypes.bool
}
