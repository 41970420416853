import {formatCurrency, formatDate} from "../../../utils/format"
import TipStateLabel from "../tip-state-label"
import {trans} from "../../../utils/translation"
import TipState from "../../../constants/TipState"

export const COLUMNS = [
  {
    accessorKey: 'name',
    header: trans('tip.columns.name'),
    accessorFn: (row) => row.name,
    enableSorting: false,
    size: 250
  },
  {
    accessorKey: 'availableFrom',
    header: trans('tip.columns.available_from'),
    accessorFn: (row) => formatDate(row.availableFrom),
    enableSorting: false,
    size: 150
  },
  {
    accessorKey: 'user',
    header: trans('tip.columns.tipster'),
    accessorFn: (row) => row.tip?.user?.name,
    enableSorting: false,
    size: 200
  },
  {
    accessorKey: 'amount',
    header: trans('tip.columns.amount'),
    accessorFn: (row) => formatCurrency(row.amount),
    enableSorting: false,
    size: 150
  },
  {
    accessorKey: 'tip.state',
    header: trans('tip.columns.state'),
    accessorFn: (row) => <TipStateLabel state={row.state}/>,
    enableSorting: false,
    size: 150
  }

]

export const STATUS_OPTIONS = {
  '': {color: 'default', label: trans('state.user.ALL')},
  [TipState.RETRIEVED]: {color: 'success', label: trans('state.tip.RETRIEVED')},
  [TipState.PAYABLE]: {
    color: 'warning',
    label: trans('state.tip.PAYABLE')
  },
  [TipState.PENDING]: {
    color: 'primary',
    label: trans('state.tip.PENDING')
  }
}
