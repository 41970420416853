import CustomPopover, {usePopover} from "../../../components/custom-popover";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stack from "@mui/material/Stack";
import Label from "../../../components/label";
import Button from "@mui/material/Button";
import {trans} from "../../../utils/translation";
import {useModalContext} from "../../../context/use-modal-context";
import { useStateCounts } from '@/hooks/use-state-counts'
import PropTypes from "prop-types";
import TipCreateModal from "../tip-create-modal";
import { alpha } from '@mui/material/styles'
import { STATUS_OPTIONS } from './tip-table-definitions'
import {FormControl} from "@mui/material";
import ListFilterTextfield from "../../../components/form/list-filter-textfield";
import IconButton from "@mui/material/IconButton";
import Iconify from "../../../components/iconify";
import MenuItem from "@mui/material/MenuItem";
import {usePage} from "@inertiajs/react";
import Roles from "../../../constants/Roles";
import { hasRole } from '@/utils/misc'
import TipLinkedEmailModal from "../tip-linked-email-modal";


export default function TipTableToolbar({
columnFilters,
setColumnFilters,
globalFilter,
setGlobalFilter,
resetAll,
url, setRefresh
}) {
  const {openModal} = useModalContext()
  const popover = usePopover()
  const counts = useStateCounts(`/tips/count?filters=${JSON.stringify(columnFilters)}`, columnFilters)
  const { auth } = usePage().props

  return (
    <>
      <Stack
        flexDirection={'row'}
        flexWrap={'wrap'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
        }}
      >
        <Tabs
          value={columnFilters.state ?? ''}
          onChange={(e, value) => {
            setColumnFilters({...columnFilters, state: value})
          }}
          sx={{
            px: 2.5
          }}
        >
          {Object.entries(STATUS_OPTIONS).map(([key, {color, label}]) => (
            <Tab
              key={key}
              iconPosition="end"
              value={key}
              label={label}
              icon={
                <Label variant={((key === null || key === columnFilters.state) && 'filled') || 'soft'} color={color}>
                  {counts[key] ?? counts['ALL']}
                </Label>
              }
            />
          ))}
        </Tabs>
        <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'center'} justifyContent={'flex-end'}>
          {(hasRole(auth.user, Roles.ROLE_ADMIN)) && (
            <Button
              variant="contained"
              color={'primary'}
              sx={{m: 1, mr: 4}}
              onClick={() => openModal('tipCreate', <TipCreateModal callback={() => setRefresh(new Date())} />)}
            >
              + {trans('tip.create')}
            </Button>
            )}
          {!hasRole(auth.user, Roles.ROLE_ADMIN) && (
            <Button
              variant="contained"
              color={'primary'}
              sx={{m: 1, mr: 4}}
              onClick={() => openModal('linkedEmails', <TipLinkedEmailModal callback={() => setRefresh(new Date())} />)}
            >
              {trans('tip.linked_email')}
            </Button>
          )}
        </Stack>
      </Stack>
      <Stack>
        <Stack
          direction={'row'}
          alignItems={'center'}
          flexWrap={'wrap'}
          spacing={2}
          flexGrow={1}
          sx={{
            p: 2
          }}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{
              flexGrow: 1,
              width: { xs: 1, md: 200 }
            }}
          >
            <FormControl sx={{ flexGrow: 1 }}>
              <ListFilterTextfield
                placeholder={trans('tip.search_placeholder')}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </FormControl>
            <IconButton onClick={popover.onOpen}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </Stack>
        </Stack>

        <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top" sx={{ width: 270 }}>
          <MenuItem
            onClick={() => {
              resetAll()
              popover.onClose()
            }}
          >
            <Iconify icon={'carbon:reset'} sx={{ width: 24, height: 24, mr: '16px' }} />
            {trans('table.reset_filter')}
          </MenuItem>
        </CustomPopover>
      </Stack>
    </>
  )
}

TipTableToolbar.propTypes = {
  columnFilters: PropTypes.object.isRequired,
  setColumnFilters: PropTypes.func.isRequired,
  globalFilter: PropTypes.string.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
  resetAll: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  setRefresh: PropTypes.func.isRequired
}

