import { useCallback, useEffect, useState } from 'react'

export function useRememberTab(path, id) {
  const [currentTab, setCurrentTab] = useState()

  const sessionKey = `${path}-tab-${id}`

  useEffect(() => {
    const savedTab = sessionStorage.getItem(sessionKey)
    if (savedTab) {
      setCurrentTab(savedTab)
    } else {
      setCurrentTab('data')

      //clear session storage for other properties
      Object.keys(sessionStorage).forEach((key) => {
        if (key.includes(`${path}-tab-`) && key !== sessionKey) {
          sessionStorage.removeItem(key)
        }
      })
    }
  }, [id])

  useEffect(() => {
    const savedTab = sessionStorage.getItem(sessionKey)
    if (savedTab) {
      setCurrentTab(savedTab)
    } else {
      setCurrentTab('data')
    }
  }, [])

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue)
    sessionStorage.setItem(sessionKey, newValue)
  }, [])

  return [currentTab, handleChangeTab]
}
