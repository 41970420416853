import { useModalContext } from "../../context/use-modal-context"
import { router } from "@inertiajs/react"
import { useForm } from '@/hooks/use-form'
import { trans } from "../../utils/translation"
import { ToastCtl } from "../../utils/toast-ctl"
import { DialogActions, DialogTitle } from "@mui/material"
import Stack from "@mui/material/Stack"
import PropTypes from "prop-types"
import DialogContent from "@mui/material/DialogContent"
import { FormProvider } from "../../context/use-form-context"
import InertiaRemoteAutocomplete from "../../components/form/inertia-remote-autocomplete"
import InertiaDatePicker from "../../components/form/inertia-date-picker"
import { LoadingButton } from "@mui/lab"
import CustomButton from "../../components/custom-button"
import useSubmit from "../../hooks/use-submit"


export default function TipCreateModal({ callback }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'POST',
      location: '/api/tip',
    },
    defaultValues: {
      user: null,
      property: null,
      propertyCompletion: null
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      callback ? callback() : router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('tipCreate')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans('tip.create_tip')}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack direction={'column'} spacing={2} sx={{ mb: 2 }}>
              <InertiaRemoteAutocomplete
                name={'user'}
                input={{
                  label: trans('tip.tipster'),
                  required: true,
                  tooltip: trans('general.not_all_users', { count: 30 })
                }}
                url={'/api/users?name=%s&itemsPerPage=30'}
              />
              <InertiaRemoteAutocomplete
                name={'property'}
                input={{
                  label: trans('general.property'),
                  required: true,
                  tooltip: trans('general.not_all_properties', { count: 30 })
                }}
                url={'/api/properties?label=%s&itemsPerPage=30'}
              />
              <InertiaDatePicker
                name={'propertyCompletion'}
                input={{
                label: trans('tip.property_completion'),
                required: true
              }}
              />
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('tipCreate')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.add')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

TipCreateModal.propTypes = {
  callback: PropTypes.func
}