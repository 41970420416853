import {useModalContext} from "../../context/use-modal-context";
import {useForm} from "../../hooks/use-form";
import useSubmit from "../../hooks/use-submit";
import {trans} from "../../utils/translation";
import {router} from "@inertiajs/react";
import {ToastCtl} from "../../utils/toast-ctl";
import {DialogActions, DialogTitle} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import InertiaAutocomplete from "../../components/form/inertia-autocomplete";
import Stack from "@mui/material/Stack";
import {FormProvider} from "../../context/use-form-context";
import CustomButton from "../../components/custom-button";
import {LoadingButton} from "@mui/lab";
import TipState from "../../constants/TipState";
import PropTypes from "prop-types";


export default function TipEditModal({ tipEntry, callback }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/tip/${tipEntry.id}/edit`,
    },
    defaultValues: {
      state: tipEntry?.state,
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      callback ? callback() : router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('tipEdit')
    })
  }

  useSubmit(() => handleSubmit())
  return (
        <>
          <DialogTitle>{trans('tip.edit')}</DialogTitle>
          <DialogContent dividers sx={{ py: 1 }}>
            <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
              <form onSubmit={handleSubmit}>
                <Stack direction={'column'} spacing={2}>
                  <InertiaAutocomplete
                    name={'state'}
                    input={{
                      label: trans('tip.state'),
                    }}
                    items = {Object.values(TipState).map((state) => ({ id: state, label: trans(`state.tip.${state}`) }))}
                    />
                </Stack>
              </form>
            </FormProvider>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={() => closeModal('tipEdit')}>{trans('button.cancel')}</CustomButton>
            <LoadingButton
              loading={submitting}
              variant={'contained'}
              onClick={() => {
                handleSubmit()
              }}
            >
              {trans('button.done')}
            </LoadingButton>
          </DialogActions>
        </>
    )
}

TipEditModal.propTypes = {
  tipEntry: PropTypes.object.isRequired,
  callback: PropTypes.func
}