import {trans} from "../../../utils/translation";
import Typography from "@mui/material/Typography";
import TipListTable from "../tip-list-table";


export default function TipListView(props) {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        {trans('tip.header')}
      </Typography>
      <TipListTable {...props} />
    </>
  )
}