import { trans } from '@/utils/translation'
import { formatDate } from '@/utils/format'
import BillingStateLabel from '../billing-state-label'
import BillingState from '../../../constants/BillingState'

export const COLUMNS = [
  {
    accessorFn: (row) => `${row.property.address.street} ${row.property.address.streetNumber}`,
    header: trans('field.street'),
    size: 250,
    id: 'street'
  },
  {
    accessorFn: (row) => `${row.property.address.postcode} ${row.property.address.city}`,
    header: trans('field.city'),
    size: 250,
    id: 'city'
  },
  {
    accessorKey: 'begin',
    header: trans('field.billing.begin'),
    Cell: ({ renderedCellValue }) => (renderedCellValue ? formatDate(renderedCellValue) : '-'),
    size: 150,
    id: 'begin'
  },
  {
    accessorKey: 'end',
    header: trans('field.billing.end'),
    Cell: ({ renderedCellValue }) => (renderedCellValue ? formatDate(renderedCellValue) : '-'),
    size: 150,
    id: 'end'
  },
  {
    accessorKey: 'state',
    header: trans('field.state'),
    // eslint-disable-next-line react/prop-types
    Cell: ({ renderedCellValue }) => <BillingStateLabel state={renderedCellValue} />,
    size: 150,
    enableSorting: false
  }
]

export const STATUS_OPTIONS = {
  '': { color: 'default', label: trans('state.billing.ALL') },
  [BillingState.OPEN]: { color: 'info', label: trans('state.billing.OPEN') },
  [BillingState.BILLABLE]: {
    color: 'warning',
    label: trans('state.billing.BILLABLE')
  },
  [BillingState.SUBMITTED]: {
    color: 'primary',
    label: trans('state.billing.SUBMITTED')
  },
  [BillingState.REVIEW]: {
    color: 'error',
    label: trans('state.billing.REVIEW')
  },
  [BillingState.DONE]: {
    color: 'success',
    label: trans('state.billing.DONE')
  }
}
