export default Object.freeze({
  PROPERTY_METER_PROTOCOL: 'PROPERTY_METER_PROTOCOL',
  TENANT_METER_PROTOCOL: 'TENANT_METER_PROTOCOL',
  INQUIRY: 'INQUIRY',
  CONTRACT: 'CONTRACT',
  ADVANCE_PAYMENT_ADJUSTMENT: 'ADVANCE_PAYMENT_ADJUSTMENT',
  ORDER_CONFIRMATION: 'ORDER_CONFIRMATION',
  INSTALLATION: 'INSTALLATION',
  USER_UPLOADED: 'USER_UPLOADED',
  PREVIOUS_CONTRACT: 'PREVIOUS_CONTRACT',
  SERVICE_INVOICE: 'SERVICE_INVOICE',
  DEVICE_INVOICE: 'DEVICE_INVOICE',
  FLOOR_PLAN: 'FLOOR_PLAN',
  PROPERTY_HEATING_BILL: 'PROPERTY_HEATING_BILL',
  APARTMENT_HEATING_BILL: 'APARTMENT_HEATING_BILL',
  INDIVIDUAL_CUSTOMER_LETTER: 'INDIVIDUAL_CUSTOMER_LETTER',
  COST_TRANSMISSION_DATA: 'COST_TRANSMISSION_DATA',
  TERMINATION_CONFIRMATION: 'TERMINATION_CONFIRMATION',
  TERMINATION_LETTER_THIRD_PARTY_MEASUREMENT: 'TERMINATION_LETTER_THIRD_PARTY_MEASUREMENT',
  DELIVERY_NOTE_DEVICES: 'DELIVERY_NOTE_DEVICES',
  TENANT_LIST: 'TENANT_LIST',
  MONTHLY_CONSUMPTION_INFORMATION: 'MONTHLY_CONSUMPTION_INFORMATION',
  INSTALLATION_PROTOCOL: 'INSTALLATION_PROTOCOL',
  SEPA_DIRECT_DEBIT_MANDATE: 'SEPA_DIRECT_DEBIT_MANDATE',
  PARTITION_DECLARATION: 'PARTITION_DECLARATION',
  APARTMENT_CONSUMPTION_ANALYSIS: 'APARTMENT_CONSUMPTION_ANALYSIS',
  CONTRACT_CONFIRMATION_SERVICE: 'CONTRACT_CONFIRMATION_SERVICE',
  CONTRACT_CONFIRMATION_DEVICE: 'CONTRACT_CONFIRMATION_DEVICE',
  BILLING_DOCUMENT: 'BILLING_DOCUMENT',
})
