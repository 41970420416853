import IconButton from '@mui/material/IconButton'
import { Card, CardHeader, Stack } from '@mui/material'
import Iconify from '@/components/iconify'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

import Avatar from '@mui/material/Avatar'
import { trans } from '@/utils/translation'
import { router, usePage } from '@inertiajs/react'
import { avatar, handleErrorResponse } from '@/utils/misc'
import CustomLabelValue from '../../components/custom-label-value'
import { useModalContext } from '@/context/use-modal-context'
import UserSelectModal from '@/sections/user/user-select-modal'
import UserEditAdminModal from '@/sections/user/user-edit-admin-modal'
import { formatDate } from '@/utils/format'
import useSingletonAxios from '@/hooks/use-singleton-axios'

export default function UserAdminFunction() {
  const { user } = usePage().props
  const { openModal, closeModal } = useModalContext()
  const request = useSingletonAxios()

  const renderFunctions = (
    <>
      <CardHeader
        title={trans('user.functions.title')}
        action={
          <IconButton
            onClick={() => {
              openModal('userDataEditAdmin', <UserEditAdminModal user={user} />)
            }}
          >
            <Iconify icon="solar:pen-bold" />
          </IconButton>
        }
      />
      <Stack spacing={1.5} sx={{ p: 3, typography: 'body2' }}>
        <CustomLabelValue label={trans('user.functions.person_number')} value={user.identifier} />
        <CustomLabelValue label={trans('user.functions.status')} value={trans(`state.user.${user.state}`)} />
        <CustomLabelValue
          label={trans('user.functions.role')}
          value={user.roles.map((role) => trans(`role.${role}`)).join(', ')}
        />
        <CustomLabelValue
          label={trans('user.functions.use_optimize')}
          value={user.settings.canManageOptimize ? trans('general.yes') : trans('general.no')}
        />
        <CustomLabelValue
          label={trans('user.functions.manage_tips')}
          value={user.settings.canManageTips ? trans('general.yes') : trans('general.no')}
        />
        <CustomLabelValue
          label={trans('user.functions.email_notification')}
          value={user.settings.notifyViaEmail ? trans('general.yes') : trans('general.no')}
        />
        <CustomLabelValue
          label={trans('profile.last_online')}
          value={user.lastLogin ? formatDate(user.lastLogin) : trans('user.not_logged_in_yet')}
        />
      </Stack>
    </>
  )

  const renderInvitedBy = (
    <>
      <CardHeader
        title={trans('user.invited_by')}
        action={
          <IconButton
            onClick={() =>
              openModal(
                'userSelect',
                <UserSelectModal
                  callback={(selected) => {
                    request({
                      url: `/api/users/${user.id}/edit-admin`,
                      method: 'PUT',
                      data: {
                        invitedBy: selected.id
                      }
                    })
                      .then(() => {
                        router.reload()
                        closeModal('userSelect')
                      })
                      .catch((e) => handleErrorResponse(e))
                  }}
                />,
                {
                  maxWidth: 'lg'
                }
              )
            }
          >
            <Iconify icon="solar:pen-bold" />
          </IconButton>
        }
      />
      <Stack direction="row" sx={{ p: 3 }}>
        <Avatar src={avatar(user.invitedBy)} sx={{ width: 48, height: 48, mr: 2 }} />

        <Stack spacing={0.5} alignItems="flex-start" sx={{ typography: 'body2' }}>
          <Typography variant="subtitle2">{user.invitedBy?.name ?? trans('user.no_user_assigned')}</Typography>

          {user.invitedBy?.email && <Typography variant="body2">{user.invitedBy.email}</Typography>}
          {user.invitedBy?.phone && <Typography variant="body2">{user.invitedBy.phone}</Typography>}
        </Stack>
      </Stack>
    </>
  )

  const renderResponsibleEmployee = (
    <>
      <CardHeader
        title={trans('user.responsible_employee')}
        action={
          <IconButton
            onClick={() =>
              openModal(
                'userSelect',
                <UserSelectModal
                  callback={(selected) => {
                    request({
                      url: `/api/users/${user.id}/edit-admin`,
                      method: 'put',
                      data: {
                        assignedStaff: selected.id
                      }
                    })
                      .then(() => {
                        router.reload()
                        closeModal('userSelect')
                      })
                      .catch((e) => handleErrorResponse(e))
                  }}
                />,
                {
                  maxWidth: 'lg'
                }
              )
            }
          >
            <Iconify icon="solar:pen-bold" />
          </IconButton>
        }
      />
      <Stack direction="row" sx={{ p: 3 }}>
        <Avatar src={avatar(user.assignedStaff)} sx={{ width: 48, height: 48, mr: 2 }} />

        <Stack spacing={0.5} alignItems="flex-start" sx={{ typography: 'body2' }}>
          <Typography variant="subtitle2">{user.assignedStaff?.name ?? trans('user.no_user_assigned')}</Typography>

          {user.assignedStaff?.email && <Typography variant="body2">{user.assignedStaff.email}</Typography>}
          {user.assignedStaff?.phone && <Typography variant="body2">{user.assignedStaff.phone}</Typography>}
        </Stack>
      </Stack>
    </>
  )

  return (
    <>
      <Card>
        {renderFunctions}

        <Divider sx={{ borderStyle: 'dashed' }} />

        {renderInvitedBy}

        <Divider sx={{ borderStyle: 'dashed' }} />

        {renderResponsibleEmployee}
      </Card>
    </>
  )
}
